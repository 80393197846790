import styled from 'styled-components';

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const StyledAircraftCarousel = styled(Carousel)`
  .carousel .slide {
    background:none !important;
    text-align:left !important;
  }

  &&&&& { 
    .control-arrow {
      top:35%;
      font-size:24px;
      color: black !important;

      :before{
          background: none !important;
          color: black !important;
          opacity: 1 !important;
          content: '>';
          border-left: 0;
      }
    }

    .control-next {
      right:-10px;
    }

    .control-prev {
      left:-10px;
      
      :before{
          content:'<';
          border-right: 0;
      }
    }

    .thumb{
      width:180px;
      padding:0;
      border: 2px solid #DCDCDC;

      &.selected, 
      &:hover {
        border: 2px solid #CE3134;
      }
    }
}
 
`