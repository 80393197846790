import styled from "styled-components";

export const RichTextWrap = styled.p`
  transition: all .5s ease-in-out;
  position: relative;
  left: 45%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: white;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  height:100%;
  text-align: left;
  transition: opacity .35s ease-in-out;

  img {
    width: unset !important;
    margin: unset !important;
  }
`
