import React from 'react';
import PropTypes from 'prop-types';

import RenderRichText from 'utils/richText/renderRichText';

import { StyledAircraftCarousel } from './styled'
import { RichTextWrap } from 'components/galleries/carouselGallery/styled';
function AircraftGalleryCarousel({ images }) {
  if (images) {
    return (
      <StyledAircraftCarousel
        infiniteLoop={true}
        useKeyboardArrows={true}
        autoPlay={true}
        showArrows={false}
        showIndicators={false}
        showStatus={false}
        selectedItem={3}
        interval={5000}
      >
        {images && images.map(el => {
          return (
            <figure key={el.gallery_image.url}>
              <img src={el.gallery_image.url} alt={el.gallery_image.alt || ""} />
              {el.image_content && 
                <RichTextWrap>
                  <RenderRichText richTextArray={el.image_content.raw ? el.image_content.raw : el.image_content} />
                </RichTextWrap>
              }
            </figure>
          )
        })}
      </StyledAircraftCarousel>
    )
  } else {
    return null;
  }

}

AircraftGalleryCarousel.propTypes = {
  images: PropTypes.array
}

export default AircraftGalleryCarousel

